import { MsalGuardConfiguration } from '@azure/msal-angular';
import { Configuration, InteractionType, LogLevel } from '@azure/msal-browser';

export const MSAL_CONFIG: Configuration = { 
    auth: {
        clientId: '13357475-28e8-4229-897a-c532dfbe1e6f',
        authority: 'https://login.microsoftonline.com/0ce83040-50e6-4ae0-bc3c-a2d9046a4a78'
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            // Uncomment this to see all logs for MSAL
            // loggerCallback: (logLevel: LogLevel, message: string): void => {
            //     console.log(message);
            // },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
};
export const API_SCOPE: string = '499ea258-a5d7-45e2-9f18-09d637509dcf/user_impersonation';
export const MSAL_GUARD_CONFIG: MsalGuardConfiguration = { interactionType: InteractionType.Redirect };
