import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ModalViewComponent } from '../../../components/modal/modal-view/modal-view.component';

@Injectable({ providedIn: 'root' })
export class ModalStackService {
    modalStack: ModalViewComponent[] = [];
    private readonly modalAddedSubject$: Subject<ModalViewComponent> = new Subject();

    get topModal(): ModalViewComponent {
        const stackLength: number = this.modalStack.length;

        if (stackLength > 0) {
            return this.modalStack[stackLength - 1];
        }

        return undefined;
    }

    get modalAdded$(): Observable<ModalViewComponent> {
        return this.modalAddedSubject$.asObservable();
    }

    addModalToStack(modalInstance: ModalViewComponent): ModalViewComponent {
        this.addToModalStack(modalInstance);

        modalInstance.componentDestroy$.subscribe((): void => {
            this.removeFromModalStack(modalInstance);
        });

        this.modalAddedSubject$.next(modalInstance);

        return modalInstance;
    }

    closeAll(reason?: any): void {
        this.modalStack
            .slice()
            .reverse()
            .forEach((modalInstance: ModalViewComponent) => {
                this.close(modalInstance, reason);
            });
    }

    close(modalInstance: ModalViewComponent, reason?: any): void {
        modalInstance.destroy(reason);
    }

    private addToModalStack(modalInstance: ModalViewComponent): ModalViewComponent {
        const { topModal }: ModalStackService = this;

        this.modalStack.push(modalInstance);
        modalInstance.stackIndex = topModal ? topModal.stackIndex + 1 : 0;

        return modalInstance;
    }

    private removeFromModalStack(modalInstance: ModalViewComponent): void {
        const modalIndexInStack: number = this.modalStack.findIndex(
            ({ stackIndex: modalStackIndex }) => modalStackIndex === modalInstance.stackIndex
        );

        if (modalIndexInStack > -1) {
            this.modalStack.splice(modalIndexInStack, 1);
        }
    }
}
