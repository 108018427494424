import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpHeaders } from '@angular/common/http';
import { IHttpWrapper, ICommonApiResponse, ICreop, CommonApiResponse, HeaderKeys } from 'cap-client';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { HttpRequestOptions, AlertModalProperties } from '@app/interfaces';
import { AlertService } from '../alert/alert.service';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
    providedIn: 'root'
})
export class HttpWrapperService implements IHttpWrapper {
    readonly defaultHeaders: any = {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
    };
    private readonly defaultHttpOptions: any = {
        observe: 'response',
        headers: new HttpHeaders(this.defaultHeaders)
    };

    constructor(
        private readonly http: HttpClient,
        private readonly alertService: AlertService,
        private readonly spinnerService: SpinnerService
    ) {}

    static getHeaders(httpHeaders: HttpHeaders): any {
        const headers: any = {};

        httpHeaders.keys().forEach((headerKey: string) => {
            headers[headerKey] = httpHeaders.get(headerKey);
        });

        return headers;
    }

    get(url: string, options: HttpRequestOptions = {}): Observable<ICommonApiResponse> {
        this.showSpinnerIfNeeded(options.showDefaultSpinner);

        return this.http.get(url, this.prepareRequestOptions(options) as any).pipe(
            map((response: any) => {
                this.hideSpinnerIfNeeded(options.showDefaultSpinner);

                return this.prepareResponse(response);
            }),
            catchError((err: HttpErrorResponse) => this.errorHandler(options, err))
        );
    }

    post(url: string, creop?: ICreop | any, options: HttpRequestOptions = {}): Observable<ICommonApiResponse> {
        this.showSpinnerIfNeeded(options.showDefaultSpinner);

        return this.http.post(url, creop, this.prepareRequestOptions(options) as any).pipe(
            map((response: any) => {
                this.hideSpinnerIfNeeded(options.showDefaultSpinner);

                return this.prepareResponse(response, creop);
            }),
            catchError((err: HttpErrorResponse) => this.errorHandler(options, err))
        );
    }

    put(url: string, body: any, options: HttpRequestOptions = {}): Observable<ICommonApiResponse> {
        this.showSpinnerIfNeeded(options.showDefaultSpinner);

        return this.http.put(url, body, this.prepareRequestOptions(options) as any).pipe(
            map((response: any) => {
                this.hideSpinnerIfNeeded(options.showDefaultSpinner);

                return this.prepareResponse(response);
            }),
            catchError((err: HttpErrorResponse) => this.errorHandler(options, err))
        );
    }

    delete(url: string, options: HttpRequestOptions = {}): Observable<ICommonApiResponse> {
        this.showSpinnerIfNeeded(options.showDefaultSpinner);

        return this.http.delete(url, this.prepareRequestOptions(options) as any).pipe(
            map((response: any) => {
                this.hideSpinnerIfNeeded(options.showDefaultSpinner);

                return this.prepareResponse(response);
            }),
            catchError((err: HttpErrorResponse) => this.errorHandler(options, err))
        );
    }

    showDefaultAlert(err: HttpErrorResponse): Observable<boolean> {
        return this.showAlert({
            errorMessage: this.getErrorMessage(err)
        });
    }

    getErrorMessage({ message, error }: HttpErrorResponse): string {
        error = this.getParsedError(error);

        return error && error.Message ? error.Message : message;
    }

    getParsedError(error: any): any {
        try {
            return JSON.parse(error);
        } catch {
            return error;
        }
    }

    private showSpinnerIfNeeded(showDefaultSpinner: boolean = true): void {
        if (showDefaultSpinner) {
            this.spinnerService.show();
        }
    }

    private hideSpinnerIfNeeded(spinnerShouldBeShowed: boolean = true): void {
        if (spinnerShouldBeShowed) {
            this.spinnerService.hide();
        }
    }

    private showAlert(alertProperties: AlertModalProperties): Observable<boolean> {
        return this.alertService.show(alertProperties);
    }

    private prepareRequestOptions(options: HttpRequestOptions): HttpRequestOptions {
        const clonedOptions: HttpRequestOptions = { ...this.defaultHttpOptions, ...options };

        if (options && options.headers) {
            clonedOptions.headers = {
                ...HttpWrapperService.getHeaders(this.defaultHttpOptions.headers),
                ...HttpWrapperService.getHeaders(options.headers as HttpHeaders)
            };
        }

        if (clonedOptions.hasOwnProperty('showDefaultAlert')) {
            delete clonedOptions.showDefaultAlert;
        }

        if (clonedOptions.hasOwnProperty('showDefaultSpinner')) {
            delete clonedOptions.showDefaultSpinner;
        }

        if (clonedOptions.hasOwnProperty('addAuthToken')) {
            delete clonedOptions.addAuthToken;
        }

        return clonedOptions;
    }

    private prepareResponse({ body, status, headers }: HttpResponse<any>, creop?: ICreop | any): ICommonApiResponse {
        const commonApiResponse: CommonApiResponse = new CommonApiResponse();

        commonApiResponse.count = this.getCountFromHeaders(headers);
        commonApiResponse.creop = creop;
        commonApiResponse.data = body;
        commonApiResponse.headers = headers;
        (commonApiResponse as any).status = status;

        return commonApiResponse;
    }

    private getCountFromHeaders(headers: any): number {
        let count: number | string = headers ? headers.get(HeaderKeys.HEADER_NAME_COUNT) : -1;

        if (typeof count !== 'number') {
            count = count ? parseInt(count) : -1;
        }

        return count;
    }

    private errorHandler(options: HttpRequestOptions, err: HttpErrorResponse): Observable<never> {
        const { showDefaultAlert = true, showDefaultSpinner } = options;

        this.hideSpinnerIfNeeded(showDefaultSpinner);

        if (showDefaultAlert) {
            this.showAlert({
                errorMessage: this.getErrorMessage(err)
            }).subscribe(() => {});
        }

        return this.prepareErrorResponse(err);
    }

    private prepareErrorResponse(err: HttpErrorResponse): Observable<never> {
        return throwError(err);
    }
}
