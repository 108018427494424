import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MsalInterceptor, MsalService } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

import { environment } from '../../../../environments/environment';
import { API_ROOT_TOKEN } from '../../../injection-tokens';
import { API_SCOPE } from '../../../config/msal.config';
import { WindowReferenceService } from '../../services/window-reference/window-reference.service';

@Injectable()
export class DataPipelineHttpInterceptor extends MsalInterceptor {
    private readonly _window: Window = this.windowReferenceService.nativeWindow;

    constructor(
        @Inject(API_ROOT_TOKEN) private readonly apiRoot: string,
        readonly msalService: MsalService,
        readonly agLocation: Location,
        private readonly windowReferenceService: WindowReferenceService
    ) {
        super(
            {
                protectedResourceMap: new Map<string, string[]>([
                    ['/api/*', [API_SCOPE]],
                    [`${apiRoot}/api/*`, [API_SCOPE]]
                ]),
                interactionType: InteractionType.Redirect
            },
            msalService,
            agLocation
        );
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url: string = req.url;

        if (req.url.startsWith('/api') && environment.production) {
            url = this.apiRoot + url;
        }

        const clonedRequest: HttpRequest<any> = req.clone({
            url
        });

        return next.handle(clonedRequest);
    }
}
