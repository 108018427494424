import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import { STORAGE_AUTH_ITEM_NAME, STORAGE_USERID_ITEM_NAME } from '@app/config';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    public accessToken: string;

    constructor(private readonly msalService: MsalService) {}

    logout(): void {
        this.cleanAuthStorageData();

        this.msalService.logout();
    }

    cleanAuthStorageData(): void {
        sessionStorage.clear();
        localStorage.removeItem(STORAGE_AUTH_ITEM_NAME);
        localStorage.removeItem(STORAGE_USERID_ITEM_NAME);
    }
}
