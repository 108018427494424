import { DataPipelineModalService } from '@datapipeline/core';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
    selector: 'datapipeline-modal-placeholder',
    templateUrl: './modal-placeholder.component.html',
    styleUrls: ['./modal-placeholder.component.scss']
})
export class ModalPlaceholderComponent implements OnInit {
    @ViewChild('modalPlaceholder', { read: ViewContainerRef, static: true }) modalPlaceholder: ViewContainerRef;

    constructor(private readonly modalService: DataPipelineModalService) {}

    ngOnInit(): void {
        this.modalService.registerModalPlaceholderRef(this.modalPlaceholder);
    }
}
