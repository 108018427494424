import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SvgSymbolComponent } from './svg-symbol/svg-symbol.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';

const components = [SvgSymbolComponent, ModalHeaderComponent];

@NgModule({
    declarations: [...components],
    exports: [...components],
    entryComponents: [],
    imports: [CommonModule, FormsModule]
})
export class SharedComponentsModule {}
