import { MeganavItem } from '@app/interfaces';
import { DataPipelineRoutes } from '../enums/routes.enums';

export function MEGANAV_CONFIG(): MeganavItem[] {
    return [
        {
            id: 'home',
            title: 'Home',
            routeName: '/home',
            tooltip: '',
            enable: true
        },
        {
            id: 'metadataCatalogs',
            title: 'Metadata Catalogs',
            tooltip: '',
            enable: true,
            subItems: [
                {
                    id: 'field',
                    title: 'Field Metadata',
                    routeName: DataPipelineRoutes.MetadataCatalogsFields,
                    tooltip: '',
                    enable: true
                },
                {
                    id: 'pipeline',
                    title: 'Pipeline Metadata',
                    routeName: '/home',
                    tooltip: '',
                    enable: true
                },
                {
                    id: 'system',
                    title: 'System Metadata',
                    routeName: '/home',
                    tooltip: '',
                    enable: true
                }
            ]
        },
        {
            id: 'contentMetadataCatalogs',
            title: 'Content Metadata Catalogs',
            tooltip: '',
            enable: true,
            subItems: [
                {
                    id: 'field',
                    title: 'Content Field Metadata',
                    routeName: DataPipelineRoutes.ContentMetadataCatalogsFields,
                    tooltip: '',
                    enable: true
                }
            ]
        }
    ];
}
