export interface KeyCodes {
    [key: string]: number;
}

export const KEY_CODES: KeyCodes = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    CTRL: 17,
    ESC: 27,
    SHIFT: 16,
    CONTROL: 17,
    ALT: 18,
    SPACE: 32,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    INSERT: 45,
    DELETE: 46,
    '8': 56,
    A: 65,
    C: 67,
    G: 71,
    K: 75,
    M: 77,
    N: 78,
    P: 80,
    U: 85,
    V: 86,
    X: 88,
    MAIN_0: 48,
    MAIN_9: 57,
    MAIN_90: 90,
    META: 91,
    NUM_0: 96,
    NUM_9: 105,
    MINUS: 109,
    DECIMAL_POINT: 110,
    DASH: 189,
    PERIOD: 190,
    FORWARD_SLASH: 191
};

export const KEY_ENTER: string = 'Enter';
export const KEY_DOWN: string = 'Down';
export const KEY_UP: string = 'Up';
export const KEY_ARROW_DOWN: string = 'ArrowDown';
export const KEY_ARROW_UP: string = 'ArrowUp';
export const KEY_TAB: string = 'Tab';
