import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICommonApiResponse } from 'cap-client';

import { WindowReferenceService } from '../window-reference/window-reference.service';
import { HttpWrapperService } from '../http-wrapper/http-wrapper.service';

@Injectable({
    providedIn: 'root'
})
export class SvgCacheService {
    private readonly svgCache: any = {};
    private readonly _svgSpriteUpdated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private readonly _window: Window = this.windowReferenceService.nativeWindow;
    private readonly svgContainerId: string = 'svgSpriteContainer';
    private _svgElement: HTMLElement;

    get svgSpriteUpdated$(): Observable<boolean> {
        return this._svgSpriteUpdated$.asObservable();
    }

    constructor(
        private readonly httpWrapperService: HttpWrapperService,
        private readonly windowReferenceService: WindowReferenceService
    ) {}

    initIconsSprite(): void {
        const svgContainer = this.getDocument().getElementById(this.svgContainerId);

        if (!svgContainer) {
            this.getDocument().body.appendChild(this.getSvgContainer());
            this.loadIconsSprite();
        }
    }

    loadIconsSprite(): void {
        this.simpleGet().subscribe(({ data }) => {
            this.getSvgContainer().innerHTML = data;
            this._svgSpriteUpdated$.next(true);
        });
    }

    getSvgIconById(iconId: string): any {
        if (this.svgCache[iconId]) {
            return this.svgCache[iconId].cloneNode(true);
        }

        const svg: HTMLElement = this.getDocument().createElement('svg'),
            symbol: SVGSymbolElement = this.getSvgContainer().querySelector(iconId),
            symbolViewBox: string = symbol.getAttribute('viewBox'),
            svgNodes = symbol.childNodes,
            svgElements = Array.prototype.filter
                .call(svgNodes, (svgNode) => {
                    return svgNode.nodeType === 1;
                })
                .map((svgElement) => svgElement.cloneNode(true));

        svg.setAttribute('viewBox', symbolViewBox);

        svgElements.forEach((svgElement) => svg.appendChild(svgElement));

        this.svgCache[iconId] = svg;

        return svg.cloneNode(true);
    }

    private simpleGet(): Observable<ICommonApiResponse> {
        return this.httpWrapperService.get('assets/patternlib/svg/icons-latest.svg', {
            showDefaultSpinner: false,
            showDefaultAlert: false,
            responseType: 'text'
        });
    }

    private getDocument(): Document {
        return this._window.document;
    }

    private getSvgContainer(): HTMLElement {
        if (!this._svgElement) {
            this._svgElement = this.getDocument().createElement('div');
            this._svgElement.id = this.svgContainerId;
        }

        return this._svgElement;
    }
}
