import { Params } from '@angular/router';

export class Utils {
    resize(): void {
        const event: Event = document.createEvent('Event');

        event.initEvent('resize', true, true);
        document.dispatchEvent(event);
    }

    trackByIndex(index: number): number {
        return index;
    }

    isUndefinedOrNull(value: any): boolean {
        return typeof value === 'undefined' || value === null;
    }

    getParamsFromUrl(url: string): Params {
        const splittedUrl: string[] = url.split('?');
        const properSplittedValueLength: number = 2;
        const params: Params = {};

        if (splittedUrl.length === properSplittedValueLength) {
            const vars: string[] = splittedUrl[1].split('&');

            for (let i: number = 0; i < vars.length; i++) {
                const pair: string[] = vars[i].split('=');

                if (pair.length >= properSplittedValueLength) {
                    const [key, ...values] = pair;

                    params[key] = decodeURIComponent(values.join('='));
                }
            }
        }

        return params;
    }
}
