import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { filter, tap } from 'rxjs/operators';

import { SvgCacheService } from './core/services/svg-cache/svg-cache.service';
import { SessionService } from './core/services/session/session.service';
import { API_SCOPE } from './config/msal.config';

@Component({
    selector: 'datapipeline-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private readonly msalBroadcastService: MsalBroadcastService,
        private readonly msalService: MsalService,
        private readonly sessionService: SessionService,
        private readonly svgCacheService: SvgCacheService,
    ) {}

    ngOnInit(): void {
        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) =>
                        status === InteractionStatus.None && this.msalService.instance.getAllAccounts().length > 0
                ),
                tap(() => {
                    this.svgCacheService.initIconsSprite();
                }),
                tap(() => {
                    this.msalService.instance
                        .acquireTokenSilent({
                            scopes: [API_SCOPE],
                            account: this.msalService.instance.getAllAccounts()[0]
                        })
                        .then((accessTokenResponse: AuthenticationResult) => {
                            this.sessionService.accessToken = accessTokenResponse.accessToken;
                        });
                })
            )
            .subscribe();
    }
}
