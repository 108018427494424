import { Component } from '@angular/core';

@Component({
    selector: 'datapipeline-modal-backdrop',
    templateUrl: './modal-backdrop.component.html',
    styleUrls: ['./modal-backdrop.component.scss']
})
export class ModalBackdropComponent {
    zIndex: number;

    setZIndex(zIndexValue: number): void {
        this.zIndex = zIndexValue;
    }
}
