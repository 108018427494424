import {
    Component,
    ChangeDetectionStrategy,
    HostListener,
    ElementRef,
    OnInit,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UtilsService, WindowReferenceService } from '@datapipeline/core';
import { MEGANAV_CONFIG } from '@app/config';
import { MeganavItem } from '@app/interfaces';

@Component({
    selector: 'datapipeline-meganav',
    templateUrl: './meganav.component.html',
    styleUrls: ['./meganav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeganavComponent implements OnInit, OnDestroy {
    activeItem: MeganavItem;
    expandedItem: MeganavItem;

    private readonly _window: Window = this.windowReferenceService.nativeWindow;
    private readonly componentDestroyed$: Subject<boolean> = new Subject();

    constructor(
        public utilsService: UtilsService,
        private readonly router: Router,
        private readonly elementRef: ElementRef,
        private readonly windowReferenceService: WindowReferenceService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        this.router.events.pipe(takeUntil(this.componentDestroyed$)).subscribe((e: RouterEvent) => {
            if (e instanceof NavigationEnd) {
                this.activateItemByPathName(e.url);

                this.changeDetectorRef.detectChanges();
            }
        });
    }

    ngOnInit(): void {
        this.activateItemByPathName(this._window.location.pathname);
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
    }

    @HostListener('document:click', ['$event'])
    outsideClickHandler({ target }: Event): void {
        if (this.elementRef.nativeElement && !this.elementRef.nativeElement.contains(target) && this.expandedItem) {
            this.expandedItem = undefined;
        }
    }

    getMeganavData(): MeganavItem[] {
        return MEGANAV_CONFIG();
    }

    itemClicked(item: MeganavItem): void {
        this.activeItem = item;
        this.expandedItem = undefined;

        if (item.routeName) {
            this.router.navigate([item.routeName]);
        } else if (item.subItems) {
            this.expandedItem = item;
        }
    }

    subItemClicked({ routeName }: MeganavItem): void {
        this.expandedItem = undefined;

        if (routeName) {
            this.router.navigate([routeName]);
        }
    }

    private activateItemByPathName(pathName: string): void {
        this.activeItem = this.getMeganavData().find(
            ({ routeName, subItems }: MeganavItem) =>
                routeName === pathName || subItems?.find(({ routeName }: MeganavItem) => routeName === pathName)
        );
    }
}
