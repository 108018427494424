import { NgModule } from '@angular/core';
import { MsalModule, MsalService, MsalGuard } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';

import { MSAL_CONFIG, MSAL_GUARD_CONFIG } from './config/msal.config';

@NgModule({
    imports: [MsalModule.forRoot(new PublicClientApplication(MSAL_CONFIG), MSAL_GUARD_CONFIG, null)],
    providers: [MsalService, MsalGuard]
})
export class AuthModule {}
