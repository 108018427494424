import { Injectable } from '@angular/core';

function getWindow(): Window {
    return window;
}

@Injectable({
    providedIn: 'root'
})
export class WindowReferenceService {
    get nativeWindow(): Window {
        return getWindow();
    }

    get document(): Document {
        return this.nativeWindow.document;
    }
}
