import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe/safe.pipe';

const pipes = [SafePipe];

@NgModule({
    declarations: [...pipes],
    exports: [...pipes],
    imports: [CommonModule]
})
export class SharedPipesModule {}
