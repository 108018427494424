import { Injectable, ViewContainerRef, ComponentRef, ApplicationRef, Type } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { OpenedModalResult, ModalContentParams } from '@app/interfaces';
import { ModalStackService } from '../modal-stack/modal-stack.service';
import { ModalViewComponent } from '../../../components/modal/modal-view/modal-view.component';
import { ModalBackdropComponent } from '../../../components/modal/modal-backdrop/modal-backdrop.component';
import { WindowReferenceService } from '../../window-reference/window-reference.service';
import { ComponentBuilderService } from '../../component-builder/component-builder.service';

@Injectable({
    providedIn: 'root'
})
export class DataPipelineModalService {
    lastOpenedModalZIndex: number;

    private vcRef: ViewContainerRef;
    private currentComponentRef: ComponentRef<ModalViewComponent>;
    private openedModalCount: number = 0;
    private backdropComponentRef: ComponentRef<ModalBackdropComponent>;
    private readonly MIN_MODAL_ZINDEX: number = 1048;
    private readonly Z_INDEX_STEP: number = 2;

    constructor(
        private readonly modalStackService: ModalStackService,
        private readonly windowReferenceService: WindowReferenceService,
        private readonly applicationRef: ApplicationRef,
        private readonly componentBuilderService: ComponentBuilderService
    ) {}

    registerModalPlaceholderRef(vcRef: ViewContainerRef): void {
        this.vcRef = vcRef;
    }

    // For calling this method in lazy loaded module's services, components, etc.
    // you have to pass component builder that was provided in this module
    openModal<T>(
        modalContentComponent: T,
        modalContentParams: ModalContentParams = {}
    ): OpenedModalResult<ModalViewComponent> {
        const componentDestroySubject$: Subject<any> = new Subject(),
            componentDestroy$: Observable<any> = componentDestroySubject$.asObservable(),
            { zIndex: customModalZIndex = -1, backdrop = true } = modalContentParams,
            _previousComponentRef: ComponentRef<ModalViewComponent> = this.currentComponentRef,
            { activeElement } = this.windowReferenceService.document;

        if (activeElement && (activeElement as HTMLElement).blur) {
            (activeElement as HTMLElement).blur();
        }

        this.currentComponentRef = this.buildComponent(ModalViewComponent);

        if (this.currentComponentRef) {
            const { instance: componentInstance } = this.currentComponentRef;

            Object.assign(componentInstance, {
                modalContentParams,
                modalContentComponent,
                backdrop,
                componentDestroy$,
                _previousComponentRef,
                destroy: this.destroyModal.bind(this, {
                    componentRef: this.currentComponentRef,
                    componentDestroySubject$
                })
            });

            this.lastOpenedModalZIndex = this.calculateZIndex(customModalZIndex);
            componentInstance.zIndex = this.lastOpenedModalZIndex;

            if (backdrop) {
                if (!this.backdropComponentRef) {
                    this.buildBackdrop();
                } else {
                    this.backdropComponentRef.instance.setZIndex(this.lastOpenedModalZIndex - 1);
                }
            }

            this.modalStackService.addModalToStack(componentInstance);

            this.openedModalCount++;

            if (!(this.applicationRef as any)._runningTick) {
                this.applicationRef.tick();
            }
        }

        return {
            componentRef: this.currentComponentRef,
            result: componentDestroy$
        };
    }

    private destroyModal({ componentRef, componentDestroySubject$ }, closeData): void {
        const { instance: componentInstance } = componentRef;

        componentDestroySubject$.next(closeData);
        componentDestroySubject$.complete();

        componentRef.destroy();

        if (this.openedModalCount !== 0) {
            this.openedModalCount--;
        }
        if (this.openedModalCount === 0) {
            this.destroyBackdrop();
            this.currentComponentRef = undefined;
            this.lastOpenedModalZIndex = undefined;

            return;
        }

        if (componentInstance.zIndex === this.lastOpenedModalZIndex) {
            const previousOpenedModalZIndex: number = this.findPreviousOpenedModalZIndex(componentInstance);

            if (previousOpenedModalZIndex) {
                const previousOpenedModalZIndexWithBackdrop = this.findPreviousOpenedModalZIndex(
                    componentInstance,
                    true
                );

                this.lastOpenedModalZIndex = previousOpenedModalZIndex;
                this.currentComponentRef = this.getPreviousOpenedModalRef(componentInstance);

                if (this.backdropComponentRef) {
                    if (previousOpenedModalZIndexWithBackdrop) {
                        this.backdropComponentRef.instance.setZIndex(previousOpenedModalZIndexWithBackdrop - 1);
                    } else {
                        this.destroyBackdrop();
                    }
                }
            }
        }

        componentInstance.zIndex = undefined;
    }

    private buildBackdrop() {
        this.backdropComponentRef = this.buildComponent(ModalBackdropComponent);

        if (this.backdropComponentRef) {
            Object.assign(this.backdropComponentRef.instance, {
                zIndex: this.lastOpenedModalZIndex - 1
            });
        }
    }

    private destroyBackdrop() {
        if (this.backdropComponentRef) {
            this.backdropComponentRef.destroy();
            this.backdropComponentRef = undefined;
        }
    }

    private buildComponent<T>(component: Type<T>): ComponentRef<T> {
        return this.vcRef ? this.componentBuilderService.buildComponent(this.vcRef, { component }) : undefined;
    }

    private calculateZIndex(customModalZIndex: number): number {
        const lastOpenedModalZIndex: number = this.lastOpenedModalZIndex || this.MIN_MODAL_ZINDEX;

        return Math.max(customModalZIndex, lastOpenedModalZIndex) + this.Z_INDEX_STEP;
    }

    private findPreviousOpenedModalZIndex(componentInstance: ModalViewComponent, withBackdrop = false): number {
        const { _previousComponentRef: previousComponentRef } = componentInstance;

        if (!previousComponentRef) {
            return;
        }

        const { instance: previousComponentInstance } = previousComponentRef,
            { zIndex, backdrop } = previousComponentInstance;

        if (withBackdrop) {
            // return previous opened modal's zIndex
            // or if it was closed or doesnt have backdrop try to find next opened modal in stack
            return zIndex && backdrop ? zIndex : this.findPreviousOpenedModalZIndex(previousComponentInstance);
        } else {
            // return previous opened modal's zIndex or if it was closed try to find next opened modal in stack
            return zIndex ? zIndex : this.findPreviousOpenedModalZIndex(previousComponentInstance);
        }
    }

    private getPreviousOpenedModalRef(componentInstance: ModalViewComponent): ComponentRef<ModalViewComponent> {
        const { _previousComponentRef: previousComponentRef } = componentInstance;

        if (!previousComponentRef) {
            return;
        }

        const { instance: previousComponentInstance } = previousComponentRef,
            { zIndex } = previousComponentInstance;

        // return previous opened modal ref or if it was closed try to find next opened modal in stack
        return zIndex ? previousComponentRef : this.getPreviousOpenedModalRef(previousComponentInstance);
    }
}
