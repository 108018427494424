import { Component, Output, EventEmitter, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'datapipeline-modal-header',
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalHeaderComponent {
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

    hasClose(): boolean {
        return this.closeModal.observers.length > 0;
    }

    onClose(): void {
        if (this.hasClose()) {
            this.closeModal.emit();
        }
    }
}
