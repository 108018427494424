import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, filter } from 'rxjs/operators';

import { AlertModalProperties } from '@app/interfaces';
import { DEFAULT_ALERT_TITLE, DEFAULT_ALERT_ERROR_MESSAGE, DEFAULT_ALERT_CLOSE_BTN_LABEL } from '@app/constants';
import { AlertComponent } from '../../components/alert/alert.component';
import { DataPipelineModalService } from '../modal/datapipeline-modal/datapipeline-modal.service';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private isAlertShown: boolean = false;

    constructor(private readonly modalService: DataPipelineModalService) {}

    show(modalProperties: AlertModalProperties = {}): Observable<boolean> {
        return this.isAlertShown ? of(undefined).pipe(filter(() => false)) : this.showAlertModal(modalProperties);
    }

    private showAlertModal(modalProperties: AlertModalProperties): Observable<boolean> {
        const resolve: AlertModalProperties = {
            modalTitle: DEFAULT_ALERT_TITLE,
            errorMessage: DEFAULT_ALERT_ERROR_MESSAGE,
            closeButtonLabel: DEFAULT_ALERT_CLOSE_BTN_LABEL,
            ...modalProperties
        };

        this.isAlertShown = true;

        return this.modalService
            .openModal(AlertComponent, {
                size: 2,
                isDraggable: false,
                resolve
            })
            .result.pipe(
                tap(() => {
                    this.isAlertShown = false;
                })
            );
    }
}
