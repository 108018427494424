import {
    Component,
    ViewChild,
    ViewContainerRef,
    OnInit,
    HostListener,
    ViewEncapsulation,
    ComponentRef,
    Input,
    ChangeDetectorRef
} from '@angular/core';
import { Observable } from 'rxjs';
import { trigger, transition, animate, style } from '@angular/animations';
import { first } from 'rxjs/operators';

import { ModalContentParams } from '@app/interfaces';
import { KEY_CODES } from '@app/constants';
import { ComponentBuilderService } from '../../../services/component-builder/component-builder.service';
import { ModalStackService } from '../../../services/modal/modal-stack/modal-stack.service';
import { Modal } from '../../../classes/modal.class';

@Component({
    selector: 'datapipeline-modal-view',
    templateUrl: './modal-view.component.html',
    styleUrls: ['./modal-view.component.scss'],
    animations: [
        trigger('modalEnter', [
            transition(':enter', [
                style({
                    transform: 'translateY(-25%)'
                }),
                animate(
                    '.3s ease-out',
                    style({
                        transform: 'translateY(0%)'
                    })
                )
            ])
        ])
    ],
    encapsulation: ViewEncapsulation.None
})
export class ModalViewComponent implements OnInit {
    @Input() modalContentComponent: any;
    @Input() modalContentParams: ModalContentParams;
    @Input() destroy: (closeData?: any) => void;
    @Input() componentDestroy$: Observable<any>;
    @Input() backdrop: boolean;
    @Input() _previousComponentRef: ComponentRef<ModalViewComponent>;
    @Input() zIndex: number;
    @Input() stackIndex: number;

    modalClass: string = '';
    enableDrag: boolean = false;
    modalDragHandler: HTMLElement;
    enableResize: boolean = false;

    @ViewChild('modalContentPlaceholder', { read: ViewContainerRef, static: true })
    modalContentPlaceholder: ViewContainerRef;

    private readonly DEFAULT_MODAL_SIZE: number = 2;

    constructor(
        private readonly componentBuilderService: ComponentBuilderService,
        private readonly modalStackService: ModalStackService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {}

    get windowClass(): string {
        const { windowClass = '' } = this.modalContentParams;

        return windowClass;
    }

    ngOnInit(): void {
        const {
            resolve = {},
            size: modalSize = this.DEFAULT_MODAL_SIZE,
            isDraggable = true,
            isResizable = false,
            contentComponentBuilder
        } = this.modalContentParams;

        if (this.modalContentPlaceholder) {
            const componentRef: ComponentRef<Modal> = (
                contentComponentBuilder ? contentComponentBuilder : this.componentBuilderService
            ).buildComponent<Modal>(this.modalContentPlaceholder, {
                component: this.modalContentComponent,
                bindings: {
                    ...resolve,
                    destroy: this.destroy.bind(this),
                    stackIndex: this.stackIndex
                }
            });

            if (isDraggable) {
                componentRef.instance.modalDragHandler$
                    .pipe(first())
                    .subscribe((modalDragHandlerElement: HTMLElement) => {
                        this.modalDragHandler = modalDragHandlerElement;
                        this.enableDrag = true;
                        this.changeDetectorRef.detectChanges();
                    });
            }

            if (isResizable) {
                this.enableResize = true;
            }

            this.modalClass = `modal-${modalSize}`;
        }
    }

    @HostListener('document:keydown', ['$event'])
    keyDownHandler({ keyCode }: KeyboardEvent): void {
        const { topModal: { stackIndex: topModalStackIndex = undefined } = {} } = this.modalStackService;
        const { useEscKey = true } = this.modalContentParams;

        if (keyCode === KEY_CODES['ESC'] && this.stackIndex === topModalStackIndex && useEscKey) {
            this.destroy();
        }
    }

    getWindowStyle(): any {
        return {
            'z-index': this.zIndex,
            'pointer-events': this.backdrop === true ? 'auto' : 'none'
        };
    }

    getClasses(): string {
        const modalContentClass: string = this.modalContentParams.modalContentClass || '';

        return modalContentClass + (this.enableResize ? ' modal-content-resizable' : '');
    }
}
