import { Injectable, ComponentRef } from '@angular/core';

import { SpinnerModalProperties } from '@app/interfaces';
import { DEFAULT_SPINNER_TITLE } from '@app/constants';
import { WindowReferenceService } from '../window-reference/window-reference.service';
import { ModalViewComponent } from '../../components/modal/modal-view/modal-view.component';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { DataPipelineModalService } from '../modal/datapipeline-modal/datapipeline-modal.service';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    private readonly _window: Window = this.windowReferenceService.nativeWindow;
    private readonly showSpinnerDelay: number = 500;
    private spinnerShowed: boolean = false;
    private timer: number;
    private modal: ComponentRef<ModalViewComponent>;

    constructor(
        private readonly windowReferenceService: WindowReferenceService,
        private readonly modalService: DataPipelineModalService
    ) {}

    isSpinnerShowed(): boolean {
        return this.spinnerShowed;
    }

    show(spinnerProperties: SpinnerModalProperties = {}): void {
        const resolve: SpinnerModalProperties = {
            spinnerTitle: DEFAULT_SPINNER_TITLE,
            ...spinnerProperties
        };

        this.spinnerShowed = true;

        this.timer = this._window.setTimeout(() => {
            if (!this.spinnerShowed || this.modal) {
                return;
            }

            this.modal = this.modalService.openModal(SpinnerComponent, {
                size: 1,
                isDraggable: false,
                resolve
            }).componentRef;

            this.timer = null;
        }, this.showSpinnerDelay);
    }

    hide(): void {
        this.spinnerShowed = false;

        if (this.timer) {
            this._window.clearTimeout(this.timer);
            this.timer = null;
        }

        if (this.modal) {
            this.modal.instance.destroy();
            this.modal = null;
        }
    }
}
