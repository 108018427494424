import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

import { Modal } from '../../classes/modal.class';

@Component({
    selector: 'datapipeline-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AlertComponent extends Modal {
    @Input() modalTitle: string;
    @Input() errorMessage: string;
    @Input() closeButtonLabel: string;

    close(): void {
        this.closeModal(true);
    }
}
