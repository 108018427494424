import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AngularDraggableModule } from 'angular2-draggable';

import { ContentComponent } from './components/content/content.component';
import { HeaderComponent } from './components/header/header.component';
import { MeganavComponent } from './components/meganav/meganav.component';
import { SharedComponentsModule } from '../shared/components/shared-components.module';
import { ModalBackdropComponent } from './components/modal/modal-backdrop/modal-backdrop.component';
import { ModalPlaceholderComponent } from './components/modal/modal-placeholder/modal-placeholder.component';
import { ModalViewComponent } from './components/modal/modal-view/modal-view.component';
import { AlertComponent } from './components/alert/alert.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SharedPipesModule } from '../shared/pipes/shared-pipes.module';
import { FooterComponent } from './components/footer/footer.component';
import { DataPipelineHttpInterceptor } from './classes/datapipeline-http-interceptor/datapipeline-http-interceptor.class';

const components = [
    ContentComponent,
    HeaderComponent,
    MeganavComponent,
    ModalBackdropComponent,
    ModalPlaceholderComponent,
    ModalViewComponent,
    AlertComponent,
    SpinnerComponent,
    FooterComponent
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        SharedComponentsModule,
        SharedPipesModule,
        AngularDraggableModule
    ],
    declarations: [...components],
    exports: [...components],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DataPipelineHttpInterceptor,
            multi: true
        }
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
        }
    }
}
