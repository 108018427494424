import {
    Injectable,
    ComponentFactoryResolver,
    ApplicationRef,
    Injector,
    ComponentRef,
    ComponentFactory,
    EmbeddedViewRef,
    Type
} from '@angular/core';

interface DataForComponentBuilding<T> {
    component: Type<T>;
    bindings?: any;
}

@Injectable({
    providedIn: 'root'
})
export class ComponentBuilderService {
    constructor(
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly appRef: ApplicationRef,
        private readonly injector: Injector
    ) {}

    buildComponent<T>(
        componentPlaceholderViewContainerRef,
        { component, bindings = {} }: DataForComponentBuilding<T>
    ): ComponentRef<T> {
        const componentFactory: ComponentFactory<any> =
            this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef: ComponentRef<T> = componentPlaceholderViewContainerRef.createComponent(componentFactory);

        Object.assign(componentRef.instance, bindings);

        return componentRef;
    }

    appendComponentToBody<T>(component: Type<T>) {
        const componentRef: ComponentRef<T> = this.componentFactoryResolver
            .resolveComponentFactory(component)
            .create(this.injector);

        this.appRef.attachView(componentRef.hostView);

        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        document.body.appendChild(domElem);

        return {
            domElem,
            componentRef
        };
    }
}
