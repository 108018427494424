import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AuthModule } from '@datapipeline/auth';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { API_ROOT_TOKEN } from './injection-tokens';
import {
    DEV_ENV_API_ROOT,
    TEST_ENV_HOST_NAME,
    TEST_ENV_API_ROOT,
    PROD_ENV_HOST_NAME,
    PROD_ENV_API_ROOT
} from './config/datapipeline.config';
import { WindowReferenceService } from './core/services/window-reference/window-reference.service';

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule,BrowserAnimationsModule, AppRoutingModule, CoreModule, HttpClientModule, AuthModule],
    providers: [
        {
            provide: API_ROOT_TOKEN,
            useFactory: (windowReferenceService: WindowReferenceService): string => {
                const { hostname }: Location = windowReferenceService.nativeWindow.location;
                let apiRootValue: string;

                if (hostname === TEST_ENV_HOST_NAME) {
                    apiRootValue = TEST_ENV_API_ROOT;
                } else if (hostname === PROD_ENV_HOST_NAME) {
                    apiRootValue = PROD_ENV_API_ROOT;
                } else {
                    apiRootValue = DEV_ENV_API_ROOT;
                }

                return apiRootValue;
            },
            deps: [WindowReferenceService]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
