import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

import { WindowReferenceService } from '@datapipeline/core';

@Component({
    selector: 'datapipeline-svg-symbol',
    templateUrl: './svg-symbol.component.html',
    styleUrls: ['./svg-symbol.component.scss']
})
export class SvgSymbolComponent implements OnInit, OnDestroy {
    @Input() ewbSvgUse: string;
    @Input() svgClass:
        | string
        | string[]
        | Set<string>
        | {
              [klass: string]: any;
          };

    svgUrl: string;

    private domTreeChanged: MutationObserver = null;
    private readonly _window: Window;

    constructor(private readonly el: ElementRef, private readonly windowReference: WindowReferenceService) {
        this._window = windowReference.nativeWindow;
    }

    ngOnInit(): void {
        this.svgUrl = this.getSvgUrl(this.getIconClass(this.ewbSvgUse));
        this.svgClass = this.svgClass || this.ewbSvgUse;

        if (this._window.MutationObserver) {
            this.domTreeChanged = new this._window.MutationObserver(
                this.onAttributeChanged.bind(this) as MutationCallback
            );
            this.domTreeChanged.observe(this.el.nativeElement, {
                attributes: true,
                attributeFilter: ['class'],
                attributeOldValue: true
            });
        }
    }

    ngOnDestroy(): void {
        if (this._window.MutationObserver && this.domTreeChanged) {
            this.domTreeChanged.disconnect();
        }
    }

    private getSvgUrl(iconClass): string {
        return `#${iconClass}`;
    }

    private getIconClass(className: string): string {
        if (!className) {
            return '';
        }

        //  The purpose of this method is to extract one 'ihs-icon-' like class from list of classes.
        //  This text could be the first one or could be somewhere inside of text separated with spaces.
        //  For example:
        //      <span ewb-svg-use="dropdown-arrow ihs-icon-chevron rotate-180"></span>

        const match: RegExpMatchArray = className.match(/(^ihs-icon-\S+)|(\sihs-icon-\S+)/g);

        return match ? match[0].trim() : '';
    }

    private onAttributeChanged(records: MutationRecord[]): void {
        const event: MutationRecord = records[0],
            oldIcon: string = this.getIconClass(event.oldValue),
            newIcon: string = this.getIconClass((event.target as Element).getAttribute('class'));

        if (oldIcon !== newIcon) {
            this.el.nativeElement.find('use').attr('xlink:href', this.getSvgUrl(newIcon));
        }
    }
}
