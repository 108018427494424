import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { SessionService } from '../../services/session/session.service';

@Component({
    selector: 'datapipeline-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
    userName: string;

    constructor(private readonly sessionService: SessionService) {}

    ngOnInit(): void {
        this.userName = 'User';
        const helper = new JwtHelperService();

        const decodedToken = helper.decodeToken(this.sessionService.accessToken);

        if (decodedToken !== null) {
            this.userName = decodedToken.name;
        }
    }

    logout(): void {
        this.sessionService.logout();
    }
}
