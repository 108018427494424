import { Subject } from 'rxjs';

export class Modal {
    modalDragHandler$: Subject<HTMLElement> = new Subject();
    destroy: (closeData?: any) => void;

    closeModal(closeData?: any): void {
        this.destroy(closeData);
    }
}
