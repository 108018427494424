import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DataPipelineRoutes } from './enums/routes.enums';
import { HomeModule } from './modules/home/home.module';
import { MetadataCatalogsModule } from './modules/metadata-catalogs/metadata-catalogs.module';

import { MsalGuard } from '@azure/msal-angular';

interface DataPipelineModule<T> {
    [moduleName: string]: T;
}

const routes: Routes = [
    {
        path: DataPipelineRoutes.Home,
        loadChildren: (): Promise<any> =>
            import('./modules/home/home.module').then(({ HomeModule }: DataPipelineModule<HomeModule>) => HomeModule),
        canActivate: [MsalGuard]
    },
    {
        path: DataPipelineRoutes.MetadataCatalogs,
        loadChildren: (): Promise<any> =>
            import('./modules/metadata-catalogs/metadata-catalogs.module').then(({ MetadataCatalogsModule }: DataPipelineModule<MetadataCatalogsModule>) => MetadataCatalogsModule),
        canActivate: [MsalGuard]
    },
    {
        path: DataPipelineRoutes.ContentMetadataCatalogs,
        loadChildren: (): Promise<any> =>
            import('./modules/metadata-catalogs/metadata-catalogs.module').then(({ MetadataCatalogsModule }: DataPipelineModule<MetadataCatalogsModule>) => MetadataCatalogsModule),
        canActivate: [MsalGuard]
    },
    { path: '**', redirectTo: DataPipelineRoutes.Home }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
